import React from "react"
import { graphql } from "gatsby"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Box, Grid } from "@chakra-ui/core"

import Brand from '../components/brand-collapsable';
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
  return <Layout>
    <SEO title="Home" />

    <Box p={5} m={10} shadow="md" borderWidth="1px">
      {/* <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>{desc}</Text> */}
      {documentToReactComponents(
        data.allContentfulFlatblock.edges[0].node.content.json
      )}
    </Box>

    <Grid
      templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(5, 1fr)"]}
      gap={6}
    >
      {data.allContentfulInventoryBrand.edges.map((brand) => {
        return <Brand brand={brand} />
      })}
    </Grid>
  </Layout>
}

export default IndexPage
export const query = graphql`
{
  allContentfulFlatblock(filter: {slug: {eq: "welcome"}, node_locale: {eq: "en-CA"}}) {
    edges {
      node {
        content {
          json
        }
      }
    }
  }
  allContentfulInventoryBrand(filter: {node_locale: {eq: "en-CA"}}) {
    edges {
      node {
        inventory_item {
          name
          slug
          images {
            fixed(width: 100) {
              height
              src
              width
            }
          }
        }
        slug
        name
        logo {
          fixed {src}
        }
      }
    }
  }
}
`
