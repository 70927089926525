import React from "react"

import {Box, Link, Image} from '@chakra-ui/core';

const BrandCollapsable = ({brand}) => {
  return (
    <Box
      bg="white"
      maxW="sm"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      textAlign="center"
    >
      <Image
        src={brand.node.logo?.fixed.src}
        alt={brand.node.name || brand.node.slug}
        ml="auto" mr="auto"
      />

      <Link mb={6} href={`/en/brands/${brand.node.slug}`}>
          Show Products
      </Link>
    </Box>
  );
}

export default BrandCollapsable
